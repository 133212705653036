import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import HomePage from './pages/studentHome';
import MainLayout from './layout/mainLayout';
import EducatorHome from './pages/educatorHome';
import EducatorAdmin from './pages/educatorAdmin';
import SuperUserHome from './pages/superuserHome';
import CommonLayout from './layout/commonLayout';
import VerifyUser from './pages/verifyUser';
import ResetPasswordPage from './pages/resetPassword';
import Help from './pages/help';
import InstructionsPage from './pages/instructions';
import React, { useContext, useEffect } from 'react';
import { RoleContext, RoleProvider } from './utils/roleContext';

const App = () => {
  return (
    <RoleProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout><Login /></MainLayout>} />
          <Route path="/home" element={<CommonLayout><RoleBasedComponent /></CommonLayout>} />
          <Route path="/verify" element={<MainLayout><VerifyUser /></MainLayout>} />
          <Route path="/resetPasswrd" element={<CommonLayout><ResetPasswordPage /></CommonLayout>} />
          <Route path="/help" element={<MainLayout><Help/></MainLayout>}/>
          <Route path="/forgotPassword" element={<MainLayout><ResetPasswordPage/></MainLayout>} />
          <Route path="/instructions" element={<CommonLayout><InstructionsPage/></CommonLayout>}/>
        </Routes>
      </BrowserRouter>
    </RoleProvider>
  );
}

const RoleBasedComponent = () => {
  const { setRole, role } = useContext(RoleContext);

  useEffect(() => {
    const storedRole = sessionStorage.getItem('role');
    if (storedRole) {
      setRole(storedRole);
    }
  },[setRole])

  switch (role) {
    case 'Student':
      return <HomePage />;
    case 'Administrator':
      return <SuperUserHome />;
    case 'Educator':
      return <EducatorAdmin />;
    case 'SuperUser':
      return <EducatorHome />;
    default:
      return null;
  }
};

export default App;