import React from "react";
import StatsViewPage from "./statsView";
import StudentsViewPage from "./studentView";

const ModalComponent = ({ isOpen, title, data, handleClose }) => {
    let studentList = data;
    let stdName = title.split('Stats of class ')
    return (
        <>
            {isOpen ? (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                        <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
                        <div className="relative z-10 w-[90%] h-[50%] mx-auto bg-white rounded-lg shadow-lg overflow-y-auto items-center justify-center">
                            <div className="flex items-center justify-between px-4 py-3 border-b">
                                <h3 className="text-lg font-medium">{title}</h3>
                                <button
                                    type="button"
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    onClick={handleClose}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex justify-center items-center mt-8 mb-5">
                                {title !== '' ? (
                                    <StatsViewPage statsList={studentList} stdName={stdName[1]} />
                                ) : (
                                    <StudentsViewPage studentList={data} maxLength={7} />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};


export default ModalComponent

