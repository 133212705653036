import React from "react";
import './pageStyles.css';

const InstructionsPage = () => {
    return (
        <div className="help-container flex justify-center flex-col items-center w-full bg-gray-100">
            <p className="text-center text-2xl font-semibold text-gray-700 mb-6">Instructions</p>
            <div className="help-card bg-white shadow-md rounded-lg p-6 w-full">
                <p className="text-justify font-serif text-gray-600 mb-4">
                    In the following scenarios, you will be interacting with a virtual human to practice building patient-physician report. You will get the most value out of the experience if you treat it as an authentic medical interview with a real person. The MPathic-IBCH system will record your verbal and non-verbal behaviors during the interaction in order to provide detailed feedback for improvement. Your camera will be used to record the interaction and review any non-verbal behaviors that may impact communication and the building of trust with the patient. This means that while you can complete the simulation anytime and anywhere, we recommend the following to enhance your experience:
                </p>
                <ol className="list-decimal list-inside pl-4 text-gray-600 font-serif">
                    <li className="mb-2">Set up your camera-enabled device on a flat surface so that you are eye-level and looking directly at the camera. Your head and shoulders should be in frame.</li>
                    <li className="mb-2">Set up in a well-lit room so that the camera is better able to capture facial expressions.</li>
                    <li>Present yourself to your patient as you would during a normal meeting.</li>
                </ol>
                <p className="text-justify font-serif text-gray-600 mt-4">When you are ready, download the MPathic Software by clicking the download button.  The installer will walk you through the installation and calibration of the software.  </p>
            </div>
        </div>
    )
}

export default InstructionsPage