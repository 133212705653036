import React, { createContext, useState, useEffect } from "react";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
    const [role, setRole] = useState(() => {
        return localStorage.getItem('role') || '';
    });

    useEffect(() => {
        localStorage.setItem('role', role);
    }, [role]);
    
    return (
        <RoleContext.Provider value={{ role, setRole }}>
            {children}
        </RoleContext.Provider>
    )
}