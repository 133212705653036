import React from "react";

const StatsViewPage = ({statsList, stdName}) => {
    return (
        <>
            <div className="block justify-center mt-8 mb-5 px-5">
                <div className="w-full overflow-x-auto">
                    <table className="w-full divide-y divide-gray1-200 border-2">
                        <thead className="bg-cardMain">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="3"
                                >
                                    Class {stdName} - Th 9:00 am - 11:00 am
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    First Patient Encounter
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    IAT
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Monique
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Reflection
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Reggie
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Reflection
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Tyrone
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Reflection
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                                    colSpan="1"
                                >
                                    Second Patient Encounter
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {statsList.map((student, index) => (
                                <tr key={index}>
                                    <td className="border border-b-0 border-gray-200" colSpan="3">
                                        <div className="flex justify-start items-center">
                                            <p className="px-6 py-3 text-sm tracking-wider text-left">{student.Metric}</p>
                                        </div>
                                    </td>
                                    <td className="border border-b-0 border-gray-200" colSpan="1">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario1}</p>
                                        </div>
                                    </td>
                                    <td className="border border-b-0 border-gray-200" colSpan="1">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario3}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                    <td colSpan="1" className="border border-b-0 border-gray-200">
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p className="px-6 py-3 text-sm tracking-wider">{student.Scenario2}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default StatsViewPage