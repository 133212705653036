import { useState } from "react";

const useToken = () => {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = tokenString;
        return userToken;
    }

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem('token', userToken);
        setToken(userToken);    
    }

    const clearToken = () => {
        localStorage.removeItem('token');
        setToken(null)
    }

    return {
        setToken: saveToken,
        token,
        clearToken,
    }
}

export default useToken;