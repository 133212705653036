import React, { useState } from 'react';
import StatsViewPage from '../../components/statsView';

const StatsView = ({ statsList }) => {
    const [stdName, setStdName] = useState('');
    return (
        <div className="w-full">
            <div className="flex items-center justify-center mt-3">
                <p className="pr-4">Select Class</p>
                <select onChange={(e) => setStdName(e.target.value)} className="rounded-md border-2 pl-3 pr-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                    <option>Select a class</option>
                    <option value="1">Class 1</option>
                    <option value="2">Class 2</option>
                    <option value="3">Class 3</option>
                    <option value="4">Class 4</option>
                    <option value="5">Class 5</option>
                    <option value="6">Class 6</option>
                    <option value="7">Class 7</option>
                    <option value="8">Class 8</option>
                    <option value="9">Class 9</option>
                    <option value="10">Class 10</option>
                </select>
            </div>
            {stdName ? (
                <StatsViewPage statsList={statsList} stdName={stdName}/>
            ) : null}
        </div>
    )
}

export default StatsView