export const URLGenerator = (token, id) => {
    const baseUrl = "https://iat.medicalcyberworldsinc.com/launch/bdaee3bbf34e335238208ebdbd80e829e43fb0b3/efba390da3460a0bab2e2133a4256fc4e00b3131/"
    //const domain = "iat.medicalcyberworldsinc.com"
    const domain = ".medicalcyberworldsinc.com"
    // Need to use url params then uncomment the below code and comment out cookie code
    //#region URLParams-Code

    const params = new URLSearchParams({
        'StudentID': id
    });
    let newUrl = `${baseUrl}?${params.toString()}`

    //#endregion

    // Need to use the cookie method then use below code and comment out urlParams code

    //#region Cookie-Code
    const cookieData = `Authorization=Bearer ${token}; domain=${domain}; path=/`;
    return {
        url: newUrl,
        cookie: cookieData
    };
    //#endregion
};