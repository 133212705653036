import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "../components/modal";
import { getRegisteredUsers , GetStudentsProgress } from "../api/getAPI";
import useToken from '../hook/useToken';

const SuperUserHome = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [icon, setIcon] = useState(faPlus);
    const [educators, setEducators] = useState([]);
    const [studentList, setStudentsList] = useState([]);
    const [title, setTitle] = useState('');
    let { token } = useToken();
    let tableData = [
        {
            "Metric": "Number of Students",
            "Scenario1": 100,
            "Scenario2": 100,
            "Scenario3": 100
        },
        {
            "Metric": "Number of Completions",
            "Scenario1": 80,
            "Scenario2": 80,
            "Scenario3": 80
        },
        {
            "Metric": "Number of Students Not Attempted",
            "Scenario1": 20,
            "Scenario2": 20,
            "Scenario3": 20
        },
        {
            "Metric": "Average Score",
            "Scenario1": 65,
            "Scenario2": 85,
            "Scenario3": 70
        },
        {
            "Metric": "Average Attempts Per Student",
            "Scenario1": 2,
            "Scenario2": 5,
            "Scenario3": 3
        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getRegisteredUsers(token, 'Yale', '0');
                setEducators(res);
            } catch (err) {
                console.log(err);
            }
        }

        fetchData()
    }, [token])


    const getStudentsList = async (id) => {
        try {
            let res = await getRegisteredUsers(token, 'Yale', id);
            let lrsRes = await GetStudentsProgress(id, 'Yale', token);
                const students = res.flatMap(user => {
                    return user.students.map(student => {
                        const progressData = lrsRes.find(lrs => lrs.studentId === student.studentId);
                        return {
                            ...student,
                            ...progressData
                        };
                    });
                });
                setStudentsList(students);
        } catch (err) {
            console.log(err);
        }
    }

    const handleOpen = (modalTitle) => {
        setIsOpen(true);
        if (modalTitle === "") {
            setIcon(faMinus);
        } else {
            setTitle(modalTitle);
            setStudentsList(tableData)
        }
    }

    const handleClose = () => {
        setIsOpen(false);
        setIcon(faPlus);
        setStudentsList([]);
        setTitle('')
    }

    return (
        <div>
            <div className="bg-subHeader p-3">
                <p className="text-center text-white font-bold">--Super Admin User Yale--</p>
            </div>
            <div className="flex justify-around p-3 bg-cardMain">
                <p>Educators Assigned</p>
            </div>
            <div>
                <div className="flex justify-center mt-8 mb-5">
                    <table className="w-[50%] divide-y divide-gray-200">
                        <thead className="bg-cardMain">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    colSpan="1"
                                >
                                    Enabled
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    colSpan="1"
                                >
                                    ID
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    colSpan="4"
                                >
                                    Email
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    User Role
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Class
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {educators.map((educator, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="flex justify-around items-center">
                                            <FontAwesomeIcon icon={icon} onClick={() => {
                                                handleOpen('')
                                                getStudentsList(educator['studentId'])
                                            }} />
                                            <div
                                                className={`w-5 h-5 rounded-md ${educator['hasCompletedRegistration']
                                                    ? 'bg-gray1-400'
                                                    : 'bg-white border border-gray1-300'
                                                    }`}
                                            ></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p>{educator['studentId']}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p>{educator['userName']}</p>
                                        </div>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p>{educator['role']}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            <p>{educator['className']}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="px-6 py-4 whitespace-nowrap">
                                            {educator['role'] === "Educator" ? (
                                                <button onClick={() => {
                                                    handleOpen(`Stats of class ${educator['className']}`)
                                                }}>Statistics</button>
                                            ) : null}
                                        </div>
                                    </td>
                                    {/* <td>
                                        <div className="flex justify-center items-center">
                                            <p className="px-6 py-3 text-sm tracking-wider text-linkText">Download LRS Records:</p>
                                            <input type="checkbox" checked={educator['Checked']} />
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalComponent isOpen={isOpen} title={title} data={studentList} handleClose={handleClose} />
        </div>
    )
}

export default SuperUserHome