import React, { useState, useEffect, useCallback } from 'react';
import uploadSVG from '../assets/upload.svg';
import './componentStyles.css';
import AlertComponent from './alert';
import { RegisterFileUpload, BundleFileUpload } from '../api/putAPI';
import useToken from '../hook/useToken';

const CreateDropOrChooseFile = () => {
    const DropOrChooseFile = ({ title, operation, folderName, BundleName }) => {
        const [files, setFiles] = useState([]);
        const [error, setError] = useState(null);
        const [isUploading, setIsUploading] = useState(false);
        const { token } = useToken();

        const onDropHandler = (ev) => {
            ev.preventDefault();
            const droppedFiles = [...ev.dataTransfer.files];
            setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        };

        const onFileChange = async (ev) => {
            setFiles((prevFiles) => [...prevFiles, ...Array.from(ev.target.files)]);
        };

        const handleAlertClose = () => {
            setError(null);
            window.location.reload();
        };

        const apiCalls = useCallback(async (files) => {
            try {
                setIsUploading(true);
                const uploadPromises = files.map((fileData, index) => {
                    if (operation === 'Register_Upload') {
                        return RegisterFileUpload(fileData, token);
                    } else if (operation === 'Bundle_Upload') {
                        return BundleFileUpload(fileData, folderName, fileData.name, token);
                    }
                    return Promise.reject(new Error('Unknown operation'));
                });

                const results = await Promise.all(uploadPromises);

                const allSuccess = results.every(res => (operation === 'Register_Upload' && res === 0) || (operation === 'Bundle_Upload' && res === true));

                if (allSuccess) {
                    setError({ code: 0, message: 'All files uploaded successfully' });
                } else {
                    setError({ code: 1, message: 'Some files failed to upload' });
                }
            } catch (error) {
                console.log(error);
                setError({ code: 1, message: 'There was an error in uploading the files\nPlease try again later' });
            } finally {
                setIsUploading(false);
            }
        }, [operation, folderName, token]);

        const onDragOver = (ev) => ev.preventDefault();

        useEffect (() => {
            if(files.length > 0) {
                apiCalls(files)
            }
        }, [files, apiCalls])

        return (
            <div
                className={`card p-8 flex flex-col justify-center items-center border-dashed border-2 bg-cardSub2 ${window.innerWidth < 768 ? 'h-auto' : 'h-[10%]'
                    }`}
            >
                <div style={{ marginTop: 20 }}></div>
                <div
                    id="drop_zone"
                    onDrop={onDropHandler}
                    onDragOver={onDragOver}
                    className={`card flex flex-col items-center bg-cardSub3 ${window.innerWidth < 768 ? 'p-4' : 'p-8'
                        }`}
                >
                    <p
                        className={`mb-2 text-cardSub2Text font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] ${window.innerWidth < 768 ? 'text-base' : 'text-xl'
                            }`}
                    >
                        {title}
                    </p>
                    <img
                        src={uploadSVG}
                        alt="file upload"
                        className={`object-fill ${window.innerWidth < 768 ? 'h-12 w-12' : 'h-16 w-16'
                            }`}
                    />
                    <div className={`${window.innerWidth < 768 ? 'text-sm' : 'text-base'}`}>
                        Drag and drop a file here
                    </div>
                    <div className={`${window.innerWidth < 768 ? 'text-sm' : 'text-base'}`}> or </div>
                    <input
                        id="file_picker"
                        type="file"
                        accept="*.*"
                        onChange={onFileChange}
                        multiple
                        className={`fp-label ${window.innerWidth < 768 ? 'text-sm' : 'text-base'
                            }`}
                    ></input>
                </div>
                {files.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index} className="flex items-center">
                                    <span>{file.name}</span>
                                    <span className="ml-2 text-green-500">✓</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {isUploading ? (
                    <div className="bg-gray1-900 fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-center z-50">
                        <p className="text-whiteFg pb-3">File upload in progress. This may take a few moments...</p>
                        <div className="bg-gray1-500 p-8 rounded-lg shadow-lg">
                            <span class="loader"></span>
                        </div>
                    </div>
                ) : null}
                {error ? (
                    <AlertComponent
                        errorCode={error.code}
                        errorMessage={error.message}
                        onClose={handleAlertClose}
                    />
                ) : null}
            </div>
        );
    };

    return DropOrChooseFile;
}

export default CreateDropOrChooseFile