import React, { useState } from 'react';
import './componentStyles.css';

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const handleClick = (e, newActiveTab) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    return (
        <div className="w-full pr-3 pl-3">
            <div className="flex flex-wrap">
                {children.map((child) => (
                    <div className='pl-5'>
                    <button
                        key={child.props.label}
                        className={`${
                            activeTab === child.props.label
                                ? 'bg-blue-900 text-semiGold'
                                : 'bg-blue-900 text-whiteFg'
                        } flex-grow rounded-lg font-medium py-2 px-4 sm:flex-1 w[100%]`}
                        onClick={(e) => handleClick(e, child.props.label)}
                    >
                        {child.props.label}
                    </button>
                    </div>
                ))}
            </div>
            <div className="py-4">
                {children.map((child) => {
                    if (child.props.label === activeTab) {
                        return <div key={child.props.label}>{child.props.children}</div>;
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

const Tab = ({ label, children }) => {
    return <div label={label} className="hidden">{children}</div>;
};

export { Tabs, Tab };