import React from 'react';
import StudentsViewPage from '../../components/studentView';

const StudentView = ({ studentList, maxLength }) => {
    return (
        <div className="flex justify-center mt-8 mb-5 pl-5 pr-5">
            <StudentsViewPage studentList={studentList} maxLength={maxLength} />
        </div>
    )
}

export default StudentView