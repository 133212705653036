import { makeAPIRequest } from "./makeApiRequest";
let config;

export const getRegisteredUsers = (token, instId, educatorID) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL_REGISTRATION + 'UserRegister/GetRegistrationRecords?institionId='+ instId + '&educatorId=' + educatorID,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }

    let res = makeAPIRequest(config)
    return res;
}

export const getBundleFiles = (instID, folderName, token) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL_BUNDLES + 'Bundles/GetBundleFiles?instituionID=' + instID + '&folderName=' + folderName,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let res = makeAPIRequest(config)
    return res;
}

export const getBundleFile = (folder, bundle, token) => {
    config = {
        method: 'GET',
        url:  process.env.REACT_APP_SERVER_URL_BUNDLES + 'Bundles/GetBundleFile?folderName=' + folder + '&bundleName=' + bundle,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }

    let res = makeAPIRequest(config);
    return res;
}

export const GetStudentsProgress = (educatorId, instId, token) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL_LRS + 'LRS/GetStudentsProgress?instituionId='+ instId + '&educatorId=' + educatorId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const GetStudentProgress = (instId, educatId, className, studId, token) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL_LRS + 'LRS/GetStudentProgress?instituionId='+instId+'&educatorId='+educatId+'&className='+className+'&studentId=' +studId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const GetStats = (instId, educatorId, token) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL_LRS + 'LRS/GetStudentStatistics?instituionId='+instId+'&educatorId='+educatorId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    let res = makeAPIRequest(config);
    return res;
}