import React, { useState, useEffect } from "react";
import useToken from '../hook/useToken';
import { GetStudentsProgress, getRegisteredUsers } from '../api/getAPI';
import { Tabs, Tab } from "../components/tabComponent";
import StudentView from "./subPages/educatorAdminStudentView";
import StatsView from "./subPages/educatorAdminStatsView";
import AlertComponent from "../components/alert";
import './pageStyles.css';

const EducatorAdmin = () => {
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [error, setError] = useState(null);
    const [studCount, setStudentCount] = useState(null)
    let { token } = useToken();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getRegisteredUsers(token, 'Yale', sessionStorage.getItem("userId").toString());
                let lrsRes = await GetStudentsProgress(sessionStorage.getItem("userId"), 'Yale', token);

                if (!res || res.length === 0 || res.every(user => !user.students || user.students.length === 0)) {
                    setError({ code: 1, message: 'You dont have any students listed under you' });
                    setRegisteredUsers([]);
                    setStudentCount(0);
                    return;
                }

                const students = res.flatMap(user => {
                    return (user.students || []).map(student => {
                        const defaultProgressData = {
                            "className": user.className,
                            "iatTaken": false,
                            "firstEncounter": false,
                            "scenario1": false,
                            "scenario1Count": 0,
                            "reflection1": false,
                            "scenario2": false,
                            "scenario2Count": 0,
                            "reflection2": false,
                            "scenario3": false,
                            "scenario3Count": 0,
                            "reflection3": false,
                            "secondEncounter": false
                        };

                        const progressData = lrsRes.find(lrs => lrs.studentId === student.studentId) ||
                            { ...defaultProgressData, studentId: student.studentId };

                        return {
                            ...student,
                            ...progressData
                        };
                    });
                });
                setRegisteredUsers(students);
            } catch (err) {
                console.log(err);
                setError({ code: 1, message: 'An error occurred while fetching student data' });
            }
        };

        fetchData()
    }, [token]);

    const handleCloseAlert = () => {
        setError(null);
    }

    const tableData = [
        {
            "Metric": "Number of Students",
            "Scenario1": 100,
            "Scenario2": 100,
            "Scenario3": 100
        },
        {
            "Metric": "Number of Completions",
            "Scenario1": 80,
            "Scenario2": 80,
            "Scenario3": 80
        },
        {
            "Metric": "Number of Students Not Attempted",
            "Scenario1": 20,
            "Scenario2": 20,
            "Scenario3": 20
        },
        {
            "Metric": "Average Score",
            "Scenario1": 65,
            "Scenario2": 85,
            "Scenario3": 70
        },
        {
            "Metric": "Average Attempts Per Student",
            "Scenario1": 2,
            "Scenario2": 5,
            "Scenario3": 3
        }
    ];


    // Calculate the maximum length of email id
    const maxEmailLength = registeredUsers.reduce((max, user) => {
        // Check if the user object has a userName property
        if (user.userName) {
            return Math.max(max, user.userName.length);
        } else {
            return max;
        }
    }, 0);

    return (
        <div>
            <div className="bg-subHeader p-3 mb-4">
                <p className="text-center text-white font-bold">-- Educator Admin User Yale --</p>
            </div>
            {studCount !== 0 ? (
                <Tabs>
                    <Tab label="Students">
                        <StudentView studentList={registeredUsers} maxLength={maxEmailLength} />
                    </Tab>
                    <Tab label="Statistics">
                        <StatsView statsList={tableData} />
                    </Tab>
                </Tabs>
            ) : (
                <div className="flex justify-center items-center">
                    <p>You dont have any students listed under you</p>
                </div>
            )}
            {error && <AlertComponent errorCode={error.code} errorMessage={error.message} onClose={handleCloseAlert} />}
        </div>
    );
};

export default EducatorAdmin;
