import React, { useEffect, useState } from "react";
import './pageStyles.css';
// import completed from '../assets/completed.png'
import { GetStudentProgress, getBundleFile } from "../api/getAPI";
// add this above for files download --> getBundleFile
import useToken from "../hook/useToken";
import { URLGenerator } from '../api/urlGenerator'
import AlertComponent from "../components/alert";
import { SendReflectionAnswers } from "../api/putAPI";

const HomePage = () => {
    const [firstEncounter, setFirstEncounter] = useState('inComplete');
    const [iatStatus, setIatStatus] = useState('inComplete');
    const [scenario1, setScenario1] = useState('inComplete');
    const [scenario1Reflection, setScenario1Reflection] = useState('inComplete');
    const [scenario2, setScenario2] = useState('inComplete');
    const [scenario2Reflection, setScenario2Reflection] = useState('inComplete');
    const [scenario3, setScenario3] = useState('inComplete');
    const [scenario3Reflection, setScenario3Reflection] = useState('inComplete');
    const [secondEncounter, setSecondEncounter] = useState('inComplete');
    const [error, setError] = useState(null);
    const [btnClicked, setBtnClicked] = useState('');
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [downloadingFiles, setDownloadingFiles] = useState([]);
    const [answers, setAnswers] = useState({
        question1: '',
        question2: '',
        question3: ''
    })
    let { token, clearToken } = useToken();

    let descriptionHeader = "Welcome to MPathic"
    let descriptonBody = [
        { body: 'Please follow the path on the left side of the screen through the time-released events of this course.  You must complete the previous activity to be allowed access to the next one. Completed events will be shown in green.' },
        { body: 'First, you will have a conversation with a patient.' },
        { body: 'Second, you will take the IAT.  All IAT instructions are given as a part of the experience.  Click the link on the left to begin. Following the IAT, and on a schedule in accordance with your class, three different interactions with virtual characters – Monique, Reggie, and Tyrone, will be made available.' },
        { body: 'All the simulated scenarios you will encounter were written to reflect the lived experiences of some patients in healthcare settings. As a part of your training and your professional development as a physician, it is important for you to be prepared to provide care to patients from diverse backgrounds. The simulations are part of a research program that uses AI to develop safe spaces for learners to practice communication skills, take risks, and grow as healthcare professionals. Refer to the Instructions Section on the top of this page for more details.' },
        { body: 'You may attempt a scenario only twice.  The software that allows you to experience the interactions will be made available via the download button for MAC and Windows at the top of the screen.  You cannot use a tablet/iPad to complete the interactions. After each interaction, but only once per character, you’ll be asked to complete a reflection. After you have completed all three character interactions and the reflections, you’ll be asked to have another conversation with a patient.' }
    ]

    const lessons = [
        { name: 'First Patient Encounter', status: firstEncounter, enabled: firstEncounter === 'completed', url: '', startBtn: false },
        { name: 'IAT', status: iatStatus, enabled: iatStatus, url: '', startBtn: true },
        { name: 'Monique', status: scenario1, enabled: iatStatus === 'completed', url: '', startBtn: false },
        { name: 'Reflection', status: scenario1Reflection, enabled: scenario1 === 'completed' && scenario1Reflection !== 'completed', url: '', startBtn: true },
        { name: 'Reggie', status: scenario2, enabled: scenario1Reflection === 'completed', url: '', startBtn: false },
        { name: 'Reflection', status: scenario2Reflection, enabled: scenario2 === 'completed' && scenario2Reflection !== 'completed', url: '', startBtn: true },
        { name: 'Tyrone', status: scenario3, enabled: scenario2Reflection === 'completed', url: '', startBtn: false },
        { name: 'Reflection', status: scenario3Reflection, enabled: scenario3 === 'completed' && scenario3Reflection !== 'completed', url: '', startBtn: true },
        { name: 'Second Patient Encounter', status: secondEncounter, enabled: scenario3Reflection === 'completed', url: '', startBtn: false }
    ];

    const questions = [
        "Looking at yourself in the video, how well do you think you expressed empathy during the patient visit?",
        "How might you apply the lessons learned from this experience to future patient interactions?",
        "What additional support or resources would help you get the most out of this experience?"
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await GetStudentProgress(sessionStorage.getItem("institionId"), sessionStorage.getItem("educatorId"), sessionStorage.getItem("className"), sessionStorage.getItem("studentId"), token);
                if (res) {
                    setIatStatus(res.iatTaken ? 'completed' : 'inComplete');
                    setFirstEncounter(res.firstEncounter ? 'completed' : 'inComplete');
                    setScenario1(res.iatTaken ? 'completed' : 'inComplete');
                    setScenario1Reflection(res.reflection1 ? 'completed' : 'incomplete');
                    setScenario2(res.reflection1 ? 'completed' : 'inComplete');
                    setScenario2Reflection(res.reflection2 ? 'completed' : 'incomplete');
                    setScenario3(res.reflection2 ? 'completed' : 'inComplete');
                    setScenario3Reflection(res.reflection3 ? 'completed' : 'incomplete');
                    setSecondEncounter(res.secondEncounter ? 'completed' : 'inComplete');
                }
            } catch (Err) {
                setError({ code: 1, message: Err.message })
            }
        }

        fetchData()
    }, [token])

    const handleOpenUrl = (status) => {
        if (status === "inComplete") {
            const urlData = URLGenerator(token, sessionStorage.getItem("studentId"));

            if (urlData) {
                // change the target in the below line from _blank to _self to display the page on the same tab
                const newWindow = window.open(urlData.url, "_self");
                clearToken();
                sessionStorage.clear();
                if (newWindow) {
                    // Set up a message event listener in the parent window
                    window.addEventListener('message', function (event) {
                        // Check if the message is from the new window
                        if (event.source === newWindow) {
                            // Send the cookie data to the new window
                            newWindow.postMessage({ type: 'setCookie', cookie: urlData.cookie }, '*');
                        }
                    }, false);

                    // Poll to check if the new window has loaded
                    const checkWindowLoaded = setInterval(() => {
                        if (newWindow.document.readyState === 'complete') {
                            clearInterval(checkWindowLoaded);
                            newWindow.postMessage('ready', '*');
                        }
                    }, 100);
                }
            }
        } else {
            setError({ code: 1, message: 'You have already taken this and cannot be retaken' })
        }
    }

    const handleOtherLessons = (lesson, index) => {
        const prevLessonName = index > 0 ? lesson[index - 1].name : null;
        setBtnClicked(prevLessonName);
    }

    const handleAcceptAnswers = (event, question) => {
        const { value } = event.target;
        const lines = value.split('\n');

        if (lines.length <= 20) {
            setAnswers(prev => ({ ...prev, [question]: value }))
        } else {
            setAnswers(prev => ({ ...prev, [question]: lines.slice(0, 20).join('\n') }));
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let data = {
            "scenarioName": btnClicked,
            "question1": answers.question1,
            "question2": answers.question2,
            "question3": answers.question3
        }
        let res = await SendReflectionAnswers(data, token)
        if (res) {
            setError({ code: 0, message: `Answers submitted successfully for scenario ${btnClicked}` })
        } else {
            setError({ code: 1, message: `Failed to submit answers for scenario ${btnClicked}` })
        }
    }

    const downloadWinBundles = async () => {
        setDownloadingFiles(['setup.exe']);
        setDownloadModalOpen(true);
        let res = await getBundleFile("Win", "setup.exe", token);
        await downloadBlob(res, "Win", "", 'setup.exe');
    }

    const downloadMacBundles = async (macSelection) => {
        setDownloadingFiles([`Setup.pkg`]);
        setDownloadModalOpen(true);
        let res = await getBundleFile("Mac", "Setup.pkg", token);
        await downloadBlob(res, "Mac", "", "Setup.pkg");
    }

    const downloadBlob = async (downloadUrl, folder, fileType, fileName) => {
        try {
            const response = await fetch(downloadUrl);
            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
            let receivedLength = 0;
            const chunks = [];
    
            const processChunk = async () => {
                const { done, value } = await reader.read();
                if (done) {
                    return;
                }
                chunks.push(value);
                receivedLength += value.length;
                setDownloadProgress(prev => ({
                    ...prev,
                    [fileName]: Math.round((receivedLength / contentLength) * 100)
                }));
                await processChunk();
            };
    
            await processChunk();
    
            const chunksAll = new Uint8Array(receivedLength);
            let position = 0;
            for (const chunk of chunks) {
                chunksAll.set(chunk, position);
                position += chunk.length;
            }
    
            const blob = new Blob([chunksAll]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error(`Error downloading ${folder} file:`, error);
            setError({ code: 1, message: `Error downloading ${folder} file:`, error });
        }
    }

    useEffect(() => {
        if (downloadingFiles.length > 0 && downloadingFiles.every(file => downloadProgress[file] === 100)) {
            setError({ code: 0, message: 'All files downloaded successfully' });
            setTimeout(() => {
                setDownloadModalOpen(false);
                setDownloadProgress({});
                setDownloadingFiles([]);
            }, 2000);
        }
    }, [downloadProgress, downloadingFiles]);

    const handleCloseAlert = () => {
        setError(null);
    }

    // Actual download modal
    const DownloadModal = () => (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${downloadModalOpen ? '' : 'hidden'}`}>
            <div className="bg-white rounded-lg p-8 max-w-sm w-full">
                <h2 className="text-2xl font-bold mb-4 text-center">Downloading Files</h2>
                {downloadingFiles.map((file, index) => (
                    <div key={index} className="mb-4">
                        <p className="text-sm font-semibold mb-1">{file}</p>
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                                style={{ width: `${downloadProgress[file] || 0}%` }}
                            ></div>
                        </div>
                        <p className="text-xs text-right mt-1">{downloadProgress[file] || 0}% Downloaded</p>
                    </div>
                ))}
                {downloadingFiles.every(file => downloadProgress[file] === 100) && (
                    <p className="text-center text-green-500 font-bold mt-4">All downloads complete!</p>
                )}
            </div>
        </div>
    );

    return (
        <div>
            <div className="card">
                <section className="bg-blue-900">
                    <div className="py-6 px-2 mx-auto max-w-screen-xl text-center lg:py-12">
                        <h1 className="mb-4 text-2xl font-semibold font-serif tracking-normal leading-none text-white md:text-4xl lg:text-4xl dark:text-white">Students Record Page</h1>
                        {/* <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p> */}
                        {/* <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                            <a href="#" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                                Get started
                                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </a>
                            <a href="#" className="py-3 px-5 sm:ms-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-70">
                                Learn more
                            </a>
                        </div> */}
                    </div>
                </section>
                <div className="py-2 px-4 bg-cardSub5">
                    <p className="text-center pb-4 text-white text-xl font-semibold">If you need to download the application, please choose your platform</p>
                    <div className="w-100">
                        <div className="flex justify-around items-center pb-5">
                            <button
                                className={`p-1 text-semibold w-[15%] btn `}
                                // ${iatStatus === "completed" ? 'btn' : 'opacity-10 cursor-not-allowed'}`} remove btn from the above and uncomment this line
                                onClick={downloadWinBundles}
                            // disabled={iatStatus !== "completed"}
                            >
                                Download Windows
                            </button>
                            <button
                                className={`p-1 text-semibold w-[15%] btn`}
                                // ${iatStatus === "completed" ? 'btn' : 'opacity-10 cursor-not-allowed'}`} remove btn from the above and uncomment this line
                                onClick={() => { downloadMacBundles("1") }}

                            // disabled={iatStatus !== "completed"}
                            >
                                Download MAC
                            </button>
                            {/* <select className="p-1 text-semibold w-[15%] btn" onChange={(e) => { downloadMacBundles(e.target.value) }}>
                                <option>Download MAC</option>
                                <option value="1">Download Mac Zip File</option>
                                <option value="2">Download Mac Installer (Available Monday)</option>
                            </select>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* //#region Masterblock */}
            <div className="flex container-1">
                <div className="flex justify-between items-center pr-4">
                    <div className="flex flex-col justify-start gap-4">
                        {lessons.map((lesson, index) => (
                            <div key={index} className="p-2">
                                <div className={`${lesson.startBtn ? ('cardContainer w-64') : ('cardContainerWithoutHover w-64')}`}>
                                    <div className={`${lesson.startBtn ? ('homeCard') : null} p-3 ${lesson.status === 'completed' ? 'homeCard-completed' : 'homeCard-incomplete'}`}>
                                        <h3 className="text-lg font-semibold text-center">{lesson.name}</h3>
                                        <div className="flex justify-center item-center">
                                            {lesson.startBtn && (
                                                <div className="flex justify-center item-center w-full">
                                                    <button className={`viewButton text-xs text-slateBlack font-semibold ${lesson.enabled ? 'viewButton-active text-white' : 'viewButton-disabled'}`} onClick={() => {
                                                        if (lesson.name === 'IAT') {
                                                            handleOpenUrl(lesson.status);
                                                        } else {
                                                            handleOtherLessons(lessons, index);
                                                        }
                                                    }} disabled={!lesson.enabled}>Start</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center mb-4 pl-4 w-full">
                    {btnClicked !== "Monique" && btnClicked !== "Reggie" && btnClicked !== "Tyrone" ? (
                        <div className="w-full text-justify">
                            <p className="text-center p-4 font-semibold text-xl">{descriptionHeader}</p>
                            {descriptonBody.map((bodyData, index) => (
                                <div key={index}>
                                    <p className="p-2 text-justify font-serif">{bodyData.body}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full text-justify">
                            <p className="text-center p-4 font-semibold text-xl">{btnClicked} Scenario: Critical Self-Reflection </p>
                            <div className="mx-auto">
                                <p className="mb-4"><b>Instructions: </b>Now that you have completed the scenario, take a few minutes to briefly reflect on the experience based on the prompts. Type your reflections.</p>
                                <form onSubmit={handleSubmit}>
                                    {questions.map((question, index) => (
                                        <div key={index} className="mb-3">
                                            <label className="block mb-2 font-semibold">
                                                {`${index + 1}. ${question}`}
                                            </label>
                                            <textarea
                                                value={answers[`question${index + 1}`]}
                                                onChange={(e) => handleAcceptAnswers(e, `question${index + 1}`)}
                                                className="w-full p-2 border border-gray-300 rounded-md"
                                                rows={5}
                                                placeholder="Enter your answer here (max 20 lines)"
                                            />
                                            <p className="text-sm text-gray-600 mt-1">
                                                Lines: {answers[`question${index + 1}`].split('\n').length} / 20
                                            </p>
                                        </div>
                                    ))}
                                    <div className="flex justify-center items-center">
                                        <button type="submit" className="border-2 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white z-10">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* //#endregion Masterblock */}
            {error && <AlertComponent errorCode={error.code} errorMessage={error.message} onClose={handleCloseAlert} />}
            {downloadModalOpen && <DownloadModal />}
        </div>
    )
}

export default HomePage
