import { makeAPIRequest } from "./makeApiRequest";
let config;

export const RegisterFileUpload = (file, tokenData) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_REGISTRATION + 'UserRegister/UploadRegistrationFile',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': `Bearer ${tokenData}`
        },
        data: {
            studentFile: file
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const BundleFileUpload = (files, folderName, BundleName, token) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_BUNDLES + 'Bundles/UploadBundleFile?folderName=' + folderName + '&bundleName=' + BundleName,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            bundleFile: files
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const StudentProgressUpdata = (instId, educatorId, data, token) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_LRS + 'LRS/UpdateStudentProgress?instituionId='+ instId + '&educatorId=' + educatorId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    }

    let res = makeAPIRequest(config);
    return res
}

export const ResetPassword = (userName, token) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_AUTHENTICATION + 'Login/ResetPassword',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            userName: userName
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const VerifyReset = (userName, password, code, token) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_AUTHENTICATION + 'Login/VerifyPasswordResetCode',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            "userName": userName,
            "newPassword": password,
            "verificationCode": code
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const SendReflectionAnswers = (data, token) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL_LRS + 'LRS/SaveScenarioReflection',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            "scenarioName": data.scenarioName,
            "reflectionAnswer1": data.question1,
            "reflectionAnswer2": data.question2,
            "reflectionAnswer3": data.question3
        }
    }

    let res = makeAPIRequest(config);
    return res;
}