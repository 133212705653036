import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './pageStyles.css';
import { RoleContext } from "../utils/roleContext";
import { login, RegisterUser } from "../api/postAPI";
import useToken from "../hook/useToken";
import AlertComponent from "../components/alert";
import Logo from '../assets/YaleLogo_Application_SignReg.png';
import mPathic from '../assets/MPathic.png';
import PasswordChecklist from "react-password-checklist";

const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=.medicalcyberworldsinc.com; SameSite=None; Secure";
}

const Login = () => {
    const [userName, setUserName] = useState('');
    const [passwd, setPasswd] = useState('');
    const [error, setError] = useState(null);
    const [activeBtn, setActiveBtn] = useState('Login');
    const [activeDisplay, setActiveDisplay] = useState('Login');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [studId, setStudId] = useState('');
    const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
    const [showVerifyChecklist, setShowVerifyChecklist] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const passwordInputRef = useRef(null);
    const { setRole } = useContext(RoleContext);
    const nav = useNavigate();
    const { setToken } = useToken();


    const userLogin = async () => {
        if(userName !== "" && passwd !== "") {
            try {
                const res = await login(userName, passwd);
                if (res.userId === userName) {
                    setToken(res.jwt);
                    sessionStorage.setItem("userId", res.studentId);
                    sessionStorage.setItem("role", res.role);
                    sessionStorage.setItem("userName", res.userId);
                    sessionStorage.setItem("educatorId", res.educatorID);
                    sessionStorage.setItem("studentId", res.studentId);
                    sessionStorage.setItem("className", res.className);
                    sessionStorage.setItem("institionId", res.institionId)
                    setRole(res.role)
                    setCookie('Authorization', `Bearer ${res.jwt}`, 7);
                    nav('/home');
                } else {
                    setError({ code: 1, message: 'Wrong username or password entered' })
                }
            } catch (error) {
                setError({ code: 1, message: error.response.data });
            }
        } else {
            setError({code: 1, message: 'Please enter the Email and password'})
        }
    }

    const handleCloseAlert = () => {
        setError(null);
        if (shouldNavigate) {
            sessionStorage.setItem('Id', studId);
            nav('/verify', { state: { Id: studId, registerUser: userName } });
        }
    }

    const handleKeyPress = (e, txtFieldName) => {
        if(e.key === "Enter") {
            if(txtFieldName === "userName") {
                e.preventDefault();
                passwordInputRef.current.focus();
            } else {
                userLogin();
            }
        }
    }

    const handleSetActiveBtn = (btnName) => {
        setActiveBtn(btnName);
        setActiveDisplay(btnName);
    }

    const handlePasswordBlur = () => {
        setShowPasswordChecklist(false);
    }

    const handleVerifyBlur = () => {
        setShowVerifyChecklist(false);
    }

    const userRegister = async () => {
        if (passwd === verifyPassword) {
            try {
                let res = await RegisterUser(studId, userName, passwd);
                if (res === "Success") {
                    setError({ code: 0, message: 'Complete the registration process by entering the code set to ' + userName + 'email id' });
                    setShouldNavigate(true);
                }
            } catch (err) {
                console.log(err)
                setError({ code: 1, message: err.response.data })
            }
        } else {
            setError({ code: 1, message: 'Password field and verify password fields dont match' });
        }
    }

    const handleForgotPassword = () => {
        nav('/forgotPassword')
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <div className="card p-6 w-[90%] max-w-[800px]">
                <div className="p-0 m-0 flex justify-center items-center">
                    <img src={Logo} alt="Yale-Logo" className="h-24 w-auto" />
                </div>
                <div className="flex justify-center items-center pb-6 m-0 pt-2">
                    <img src={mPathic} alt="mPathicLogo" className="h-8 w-auto" />
                </div>
                <div className="mx-auto w-full max-w-[400px]">
                    <div className="flex justify-between items-center">
                        <button className={`px-10 font-bold text-xl ${activeBtn === "Login" ? 'text-blue-600' : 'text-blue-900'}`} onClick={() => handleSetActiveBtn('Login')}>Login</button>
                        <button className={`px-10 font-bold text-xl ${activeBtn === "Register" ? 'text-blue-600' : 'text-blue-900'}`} onClick={() => handleSetActiveBtn('Register')}>Register</button>
                    </div>
                    <hr className="border-2 border-blue-900 mb-4" />
                    {activeDisplay === 'Login' ? (
                        <>
                            <div className="grid grid-cols-2 gap-4 pt-5">
                                <div>
                                    <label htmlFor="username" className="form-label font-semibold">Email</label>
                                </div>
                                <div>
                                    <input type="email" className="input-control" id="username" onChange={(e) => setUserName(e.target.value)} required onKeyDown={(e) => {handleKeyPress(e, 'userName')}} />
                                </div>
                                <div>
                                    <label htmlFor="inputPassword4" className="form-label font-semibold">Password</label>
                                </div>
                                <div>
                                    <input type="password" className="input-control" id="inputPassword4" ref={passwordInputRef} onChange={(e) => setPasswd(e.target.value)} required onKeyDown={(e) => {handleKeyPress(e, 'password')}} />
                                </div>
                            </div>
                            <div className="flex justify-center items-center pt-5">
                                <button type="submit" className="loginBtn p-2 font-semibold w-[50%] text-white" onClick={userLogin}>Login</button>
                            </div>
                            <div className="flex justify-center items-center pt-5">
                                <input type="checkbox" id="remember" />
                                <label htmlFor="remember">Remember Me</label>
                            </div>
                            <div className="flex justify-center items-center pt-5">
                                <button onClick={handleForgotPassword}>Lost your password?</button>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between pt-2 gap-4">
                                <div>
                                    <label htmlFor="studentId" className="form-label font-semibold">Student ID</label>
                                </div>
                                <div>
                                    <input type="text" placeholder="Stud101" className="input-control flex-grow" id="studentId" onChange={(e) => setStudId(e.target.value)} />
                                </div>
                            </div>
                                <>
                                    <div className="grid grid-cols-2 gap-4 pt-2 card p-5">
                                        <div>
                                            <label htmlFor="username" className="form-label">Email</label>
                                        </div>
                                        <div>
                                            <input type="email" className="input-control" id="username" onChange={(e) => setUserName(e.target.value)} required />
                                        </div>
                                        <div>
                                            <label htmlFor="inputPassword4" className="form-label flex-grow">Password</label>
                                        </div>
                                        <div className="relative flex items-center">
                                            <input type="password" className="input-control" id="inputPassword4" onChange={(e) => setPasswd(e.target.value)} onFocus={() => setShowPasswordChecklist(true)} onBlur={handlePasswordBlur} required />
                                            <div className={`bg-white p-4 rounded-md shadow-md w-[100%] absolute right-0 left-[110%] top-0 z-10 sm:w-[200%] sm:left-[100%] ${!showPasswordChecklist ? 'hidden' : ''}`}>
                                                <div className="relative">
                                                    <div className="absolute top-4 left-[-12px] w-0 h-0 border-t-[8px] border-t-transparent border-r-[12px] border-r-white border-b-[8px] border-b-transparent"></div>
                                                    <PasswordChecklist
                                                        rules={["minLength", "specialChar", "number", "capital", "lowercase", "maxLength"]}
                                                        minLength={8}
                                                        maxLength={20}
                                                        value={passwd}
                                                        specialCharsRegex={/[~`¿¡!#$%\\^&*€£@+÷=\-[\]\\;,/{}\\(\\)|\\:<>\\?\\.\\_]/g}
                                                        className="text-xs overflow-y-scroll"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="verifyPassword4" className="form-label">Verify</label>
                                        </div>
                                        <div className="relative flex items-center">
                                            <input type="password" className="input-control" id="verifyPassword4" onChange={(e) => setVerifyPassword(e.target.value)} onFocus={() => setShowVerifyChecklist(true)} onBlur={handleVerifyBlur} required />
                                            <div className={`bg-white p-4 rounded-md shadow-md w-[100%] absolute right-0 left-[110%] top-0 z-10 sm:w-[200%] sm:left-[100%] ${!showVerifyChecklist ? 'hidden' : ''}`}>
                                                <div className="relative">
                                                    <div className="absolute top-4 left-[-12px] w-0 h-0 border-t-[8px] border-t-transparent border-r-[12px] border-r-white border-b-[8px] border-b-transparent"></div>
                                                    <PasswordChecklist
                                                        rules={["minLength", "specialChar", "number", "capital", "lowercase", "maxLength", "match"]}
                                                        minLength={8}
                                                        maxLength={20}
                                                        value={passwd}
                                                        valueAgain={verifyPassword}
                                                        specialCharsRegex={/[~`¿¡!#$%\\^&*€£@+÷=\-[\]\\;,/{}\\(\\)|\\:<>\\?\\.\\_]/g}
                                                        className="text-xs overflow-y-scroll"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center pt-5">
                                        <button type="submit" className="loginBtn p-2 font-semibold w-[50%] text-white" onClick={userRegister}>Register</button>
                                    </div>
                                </>
                        </div>
                    )}
                </div>
            </div>
            {error && <AlertComponent errorCode={error.code} errorMessage={error.message} onClose={handleCloseAlert} />}
        </div>
    )
}

export default Login
