import React from "react";
import './pageStyles.css';

const Help = () => {
    return (
        <div className="help-container flex justify-center flex-col items-center w-full min-h-screen bg-gray-100">
            <div className="flex flex-col items-center justify-center h-full">
                <p className="text-lg text-gray-600 mb-4">Please see the original email for instructions on how to register.</p>
                <a href="/" type="button" className="loginBtn bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md">
                    Go To Login
                </a>
            </div>
        </div>
    );
};

export default Help;
