import { makeAPIRequest } from "./makeApiRequest";
let config;

export const DeleteBundles = (folderName, BundleName, token) => {
    config = {
        method: 'DELETE',
        url: process.env.REACT_APP_SERVER_URL_BUNDLES + 'Bundles/DeleteBundleFile?folderName=' + folderName + '&bundleName=' + BundleName,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }

    let res = makeAPIRequest(config);
    return res;
}